@import url("https://fonts.googleapis.com/css?family=Playfair+Display");
@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");

.languages {
  background-color: black;
  background-position: center;
  background-size: cover;
  max-height: 800px;
  height: 110vh;
  padding-bottom: 50px;
}
.languages__title {
  display: flex;
  justify-content: center;
}
.languages__title-text {
  font-family: "Russo One", sans-serif;
  font-size: 70px;
  font-weight: 500;
  color: #ffeb99;
  margin-left: 80px;
}
.languages__paragraph {
  display: flex;
  justify-content: center;
}
.languages__paragraph-text {
  font-family: "Playfair-Display", sans-serif;
  font-size: 25px;
  color: #ffeb99;
}
.languages__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 90px;
  row-gap: 30px;
  padding-top: 40px;
}
.languages__images-storybook {
  height: 96px;
  width: 96px;
}
.languages__images:hover {
  transform: scale(1.5);
}
.languages__images {
  transition: transform 0.5s;
  height: 96px;
  width: 96px;
}

@media only screen and (max-width: 768px) {
  .languages {
    height: 90vh;
  }
  .languages__title-text {
    font-size: 50px;
    margin-left: 0;
    text-align: center;
  }

  .languages__paragraph {
    margin-top: 20px;
  }

  .languages__paragraph-text {
    font-size: 20px;
  }

  .languages__list {
    justify-content: space-around;
    padding: 20px;
    column-gap: 30px;
    row-gap: 20px;
  }

  .languages__images {
    height: 64px;
    width: 64px;
  }

  .languages__images-storybook {
    height: 64px;
    width: 64px;
  }
}
