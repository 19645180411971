@import url("https://fonts.googleapis.com/css?family=Playfair+Display");
@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");
.header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: black;
  background-position: center;
  background-size: cover;
  max-height: 120px;
  height: 120px;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 1;
  overflow: hidden;
}

.header__logo {
  position: relative;
  left: 0px;
  color: azure;
}
.header__logo-image {
  width: 150px;
}
.header__logo-image:hover {
  cursor: pointer;
  opacity: 0.6;
}
.header__nav {
  display: flex;
  font-family: "Russo One", sans-serif;
  font-weight: 300;
  z-index: 1;
  font-size: 40px;
}

.header__links {
  color: white;
  text-decoration: none;
  padding: 14px 55px;

  transition: color 0.5s ease, box-shadow 0.5s ease;
}

.header__links:hover {
  color: black;
}

.header__social {
  right: 0px;
  padding: 14px 16px;
  display: flex;
  flex-direction: column;
}

.linkedin__logo,
.github__logo,
.instagram__logo {
  height: 30px;
  width: 30px;
  padding-left: 5px;
  padding-right: 5px;
}

.linkedin__logo:hover,
.github__logo:hover,
.instagram__logo:hover,
.resume__button:hover {
  cursor: pointer;
  opacity: 0.6;
}

.header__links:hover {
  box-shadow: inset 300px 0 0 0 white;
  color: black;
}
.resume__button {
  width: 80px;
  height: 50px;
  position: relative;
  right: 22px;
  background-color: white;
  border-radius: 10px;
  font-family: "Russo One", sans-serif;
  font-size: 15px;
}
@media screen and (min-width: 769px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
  }
}
/* Mobile Styles */
@media screen and (max-width: 768px) {
  .header {
    max-height: initial;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
  }

  .header__logo {
    position: relative;
    bottom: -18px;
    margin-bottom: 20px;
  }

  .header__logo-image {
    width: 160px;
  }

  .header__nav {
    display: none;
  }

  .header__links {
    padding: 10px 25px;
  }

  .header__social {
    position: relative;
    flex-direction: row;
    align-items: center;
    display: flex;
    justify-content: center;
    bottom: 160px;
    padding: 0;
  }

  .resume__button {
    padding: 0px 0px;
    margin-right: 0;
    width: 70px;
    color: black;
  }
  .resume__button-container {
    padding-left: 180px;
  }
}
