@import url("https://fonts.googleapis.com/css?family=Playfair+Display");
@import url("https://fonts.googleapis.com/css?family=Urbanist");
@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");

.introduction {
  background-color: black;
  background-position: center;
  background-size: cover;
  max-height: 2000px;
  height: 120vh;
  overflow-x: hidden;
  overflow-y: hidden;
}
.introduction__image {
  position: relative;
  top: 380px;
  margin-right: 40px;
  max-width: 25%;
  border-radius: 100px;
}
.introduction__picture {
  display: flex;
  justify-content: flex-end;
}
.name__wrapper {
  display: flex;
  position: absolute;
  justify-content: center;
  top: 230px;
  margin-left: 150px;
}

.title__wrapper {
  display: flex;
  position: absolute;
  justify-content: center;
  margin-left: 150px;
  top: 400px;
}
.name__text {
  font-family: "Russo One", sans-serif;
  font-size: 80px;

  font-weight: 900;
  z-index: 0;
  color: white;
}

.title__text {
  font-family: "Russo One", sans-serif;
  font-size: 40px;
  color: #ffeb99;
  overflow: hidden;
  white-space: nowrap;
  animation: typing 5s steps(40, end) infinite;
}
.title__text::after {
  content: " ";
  display: inline-block;
  height: 1em;
  animation: typing 5s steps(40, end) infinite; /* Adjust the duration as needed */
}
@keyframes typing {
  0% {
    width: 0;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}

.things > .content {
  float: left;
  width: 20%;
  height: 500px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

.things > .content h1 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  height: 150px;
}

@media screen and (max-width: 768px) {
  .introduction {
    height: 90vh;
  }
  .introduction__picture {
    display: flex;
    justify-content: center;
    position: relative;
  }
  .introduction__image {
    max-width: 250px;
    top: 200px;
    border-radius: 50px;
    margin-right: 0;
  }

  .name__wrapper {
    top: 150px;
    margin-left: 0;
    text-align: center;
  }

  .title__wrapper {
    top: 300px;
    right: 30px;
    margin-left: 0;
    margin-top: 15px;
    text-align: center;
  }

  .name__text {
    font-size: 60px;
  }

  .title__text {
    font-size: 30px;
  }
}
