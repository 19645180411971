@import url("https://fonts.googleapis.com/css?family=Playfair+Display");
@import url("https://fonts.googleapis.com/css?family=Urbanist");
@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");

.about {
  padding-bottom: 50px;
  background-color: black;
  max-height: none;
  height: auto;
}
.about__container {
  display: flex;
}

.about__title {
  display: flex;
  justify-content: center;
}
.about__paragraph {
  flex: 1;
}
.about__title-text {
  font-family: "Russo One", sans-serif;
  font-size: 70px;
  font-weight: 900;
  color: #ffeb99;
}
.about__paragraph-text {
  font-family: "Playfair-Display", sans-serif;
  font-size: 25px;
  line-height: 50px;
  margin-right: 100px;
  margin-left: 60px;
  color: white;
  line-height: 1.5;
}

.things-about > .content-about {
  float: left;
  width: 20%;
  height: 500px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

.things-about > .content-about h1 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  height: 150px;
}
.about__image {
  height: 600px;
  border-radius: 100px;
  flex-shrink: 0;
  margin-right: 20px;
  margin-left: 150px;
  margin-top: 60px;
}

@media screen and (max-width: 768px) {
  .about {
    margin-top: 0;
  }
  .about__title-text {
    font-size: 50px;
  }

  .about__paragraph-text {
    font-size: 18px;
    line-height: 30px;
    margin-right: 20px;
    margin-left: 20px;
  }

  .about__container {
    flex-direction: column;
    display: flex;
    align-items: center;
  }

  .about__image {
    height: 400px;
    width: 300px;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 20px;
    border-radius: 100px;
  }
}
