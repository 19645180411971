@import url("https://fonts.googleapis.com/css?family=Sometype+Mono");

.footer {
  display: flex;
  justify-content: space-around;
  font-family: "Sometype Mono", sans-serif;
  font-size: 20px;
  padding: 40px 0 40px;
  font-weight: 500px;
  line-height: 20px;
  font-size: 16px;
  background-color: black;
  background-position: center;
  background-size: cover;
  color: white;
}
@media only screen and (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    font-size: 14px;
    text-align: center;
  }
}
