@import url("https://fonts.googleapis.com/css?family=Playfair+Display");
@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");

form {
  display: grid;
  flex-direction: row;
}
.contact__form {
  background-color: black;
  padding-top: 10px;
  padding-bottom: 50px;
}
.form__title {
  display: flex;
  justify-content: center;
  font-family: "Russo One", sans-serif;
  font-size: 70px;
  font-weight: 900;
  color: #ffeb99;
}
.form__container {
  display: flex;
  justify-content: center;
}
.form__label {
  display: block;
  text-align: center;
  margin: 0 0 2vh 0;
}
.form__input {
  display: block;
  width: 300px;
  height: 30px;
  padding: 0.5rem 0.8rem 0.5rem 0.8rem;
  margin-bottom: 25px;
}
.form__textarea {
  height: 15vh;
  width: 730px;

  padding: 0.5rem 0.8rem 0.5rem 0.8rem;
  margin-bottom: 25px;
}

.form__top-group {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 100px;
}
.form__bottom-group {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 100px;
}
.email__button {
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: black;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  font-family: "Russo One", sans-serif;
  font-size: 25px;
  border-color: white;
}
.email__button:hover {
  box-shadow: inset 150px 0 0 0 white;
  color: black;
  cursor: pointer;
}
input::placeholder {
  font-family: "Playfair-Display", sans-serif;
  font-size: 25px;
  color: gray;
}
textarea::placeholder {
  font-family: "Playfair-Display", sans-serif;
  font-size: 25px;
  color: gray;
}
input {
  font-family: "Playfair-Display", sans-serif;
  font-size: 25px;
  color: black;
}
textarea {
  font-family: "Playfair-Display", sans-serif;
  font-size: 25px;
  color: black;
}

@media only screen and (max-width: 768px) {
  .form__title {
    font-size: 50px;
    text-align: center;
  }

  .form__input,
  .form__textarea {
    width: calc(100% - 20px);
    max-width: 100%;
  }

  .form__top-group,
  .form__bottom-group {
    flex-direction: column;
    gap: 20px;
  }

  .form__contents {
    width: 100%;
  }

  .email__button {
    width: 100%;
  }

  .email__button:hover {
    box-shadow: none;
  }

  input::placeholder,
  textarea::placeholder {
    font-size: 20px;
  }

  input,
  textarea {
    font-size: 20px;
  }
}
