@import url("https://fonts.googleapis.com/css?family=Playfair+Display");
@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");

.projects {
  max-height: none;
  height: auto;
  background-color: black;
  background-position: center;
  background-size: cover;
  padding-bottom: 50px;
}

.projects__title {
  display: flex;
  justify-content: center;
}

.projects__title-text {
  font-family: "Russo One", sans-serif;
  font-size: 70px;
  font-weight: 900;
  color: #ffeb99;
}

.projects__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.projects__wtwr-container,
.projects__golf-container,
.projects__slop-container {
  width: 25%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wtwr__container,
.golf__container,
.slop__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wtwr__image,
.golf__image,
.slop__image {
  border-style: solid;
  border-width: 2px;
  border-color: white;
  height: 140px;

  margin-bottom: 20px;
}

.image__title {
  font-family: "Russo One", sans-serif;
  font-size: 25px;
  font-weight: 900;
  color: #ffeb99;
  margin-bottom: 20px;
}

.projects__wtwr-paragraph,
.projects__golf-paragraph,
.projects__slop-paragraph {
  width: 350px;
  height: 500px;
  border-radius: 50px;
  display: flex;
  justify-content: center;

  opacity: 0.9;
  flex-direction: column;
  justify-content: flex-start;
}

.projects__wtwr-paragraph-text,
.projects__golf-paragraph-text,
.projects__slop-paragraph-text {
  font-family: "Playfair-Display", sans-serif;
  font-size: 25px;
  line-height: 1.2;
  color: white;
  max-width: 750px;
  text-align: center;
}
.wtwr__list,
.golf__list,
.slop__list {
  list-style: disc;
  padding: 0;
  margin: 0;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.wtwr__list-item,
.golf__list-item,
.slop__list-item {
  font-family: "Playfair-Display", sans-serif;
  font-size: 20px;
  color: white;
  margin-bottom: 10px;
  width: max-content;
}
.repo__link {
  font-family: "Russo One", sans-serif;
  font-size: 20px;
  color: #ffeb99;
  margin-bottom: 20px;
  width: max-content;
}
.link__to-repo {
  text-decoration: none;
  color: white;
  cursor: pointer;
}
.link__to-repo:hover {
  opacity: 0.6;
}

@media only screen and (max-width: 768px) {
  .projects__title-text {
    font-size: 50px;
  }
  .projects__container {
    flex-direction: column;
    align-items: center;
  }
  .image__title {
    font-size: 30px;
  }

  .projects__wtwr-container,
  .projects__golf-container,
  .projects__slop-container {
    width: 90%;
    margin-bottom: 20px;
  }

  .wtwr__list-item,
  .golf__list-item,
  .slop__list-item {
    margin-right: 20px;
  }
  .wtwr__image,
  .golf__image,
  .slop__image {
    height: 150px;
  }

  .projects__wtwr-paragraph,
  .projects__golf-paragraph,
  .projects__slop-paragraph {
    width: 90%;
    height: auto;
    margin-top: 20px;
  }
}
